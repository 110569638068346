import React from 'react';
import { nanoid } from 'nanoid';
import isEmpty from 'lodash/isEmpty';

import Heading from '../../../../components/atoms/Heading';
import Spacing from '../../../../components/atoms/Spacing';
import Paragraph from '../../../../components/atoms/Paragraph';
import MaxWidth from '../../../../components/atoms/MaxWidth';
import Iframely from '../../../../components/atoms/Iframely';
import Image from '../../../../components/atoms/Image';

import generalStyles from './style/general.module.css';

const CampaignInfo = ({ campaign }) => {
  const contentJson = campaign.content;
  const { content = [] } = contentJson;

  if (isEmpty(contentJson)) return;

  return (
    <div className={generalStyles.moreInfoContent}>
      <Spacing size={8}>
        <MaxWidth value={645} center>
          {content.map((item) => {
            if (item.type === 'heading') {
              const { level = 1 } = item.attrs;

              if (!item.content) {
                return <div className="py-05" />;
              }

              return (
                <Spacing size={[2, 2, 25]} key={nanoid()}>
                  {item.content?.map((node) => {
                    return (
                      <Heading
                        size={level === 1 ? ['m', 'm', 'l'] : ['s', 's', 'm']}
                        key={nanoid()}
                      >
                        {node.text ?? ''}
                      </Heading>
                    );
                  })}
                </Spacing>
              );
            }

            if (item.type === 'paragraph') {
              if (!item.content) {
                return <div className="py-05" />;
              }

              return (
                <Spacing size={[2, 2, 25]} key={nanoid()}>
                  {item.content?.map((node) => {
                    return (
                      <Paragraph
                        className={generalStyles.moreInfoText}
                        size="xs"
                        dangerouslySetInnerHTML={{
                          __html: node.text ?? ''
                        }}
                      />
                    );
                  })}
                </Spacing>
              );
            }

            if (item.type === 'image') {
              const { src } = item.attrs;

              if (src) {
                return (
                  <Spacing size={[2, 2, 25]} key={src}>
                    <Image
                      className="mx-auto block"
                      src={src}
                      alt={campaign.title}
                      withPlaceholder={false}
                    />
                  </Spacing>
                );
              }
            }

            if (item.type === 'iframely') {
              const { src } = item.attrs;

              if (src) {
                return (
                  <Spacing size={[2, 2, 25]} key={src}>
                    <Iframely url={src} />
                  </Spacing>
                );
              }
            }

            return null;
          })}
        </MaxWidth>
      </Spacing>
    </div>
  );
};

export default CampaignInfo;
